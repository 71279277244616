.slideContainer {
  max-width: 1300px;
  margin: 0 auto;
  margin-bottom: 24px;
  margin-top: 30px;
  position: relative;
}

.textContainer {
  position: absolute;
  z-index: 1000;
  height: 100%;
  margin-top: 0;
  margin-left: 0;
  color: #fff;
  width: 50%;
  max-width: 400px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 28px;
  font-size: 17px;
  text-align: justify;
  box-shadow: 10px 0 10px rgba(0, 0, 0, 0.2);
}

.logo {
  margin: 0 auto;
  max-width: 150px;
  margin-top: 40px;
}

.logoContainer {
  text-align: center;
  width: 100%;
}
