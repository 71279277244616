:root {
  /* Colors */
  --dark-blue: #03045eff;
  --secondary-blue: #0077b6ff;
  --primary-blue: #3892c2;
  --light-blue: #90e0efff;
  --bg-blue: #caf0f8ff;
  --bg-color: #f2f8fa;
  --bg-yellow: #f1dca7;
  --text-color: #efefef;
  --card-light-bg-color: #def0f3;
  --dark-text-color: #222;
  --grey-color: #d4d3d3;
  --dark-grey: #949494;
  --light-dark: #555555;
  --delete-color: #df372b;
  --divider-color: rgba(0, 0, 0, 0.1);
  --dark-bg: #222;
  --soft-dark: #272724;
  /* Global */
  --letter-spacing: 0.08rem;
  --transition: all 0.2s ease;
  --btn-transition: all 0.125s ease;
  --radius: 0.25rem;
  --img-radius: 0.25rem 0.25rem 0rem 0rem;
  --card-radius: 0rem 0rem 0.25rem 0.25rem;
  --btn-radius: 16px;
  --light-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  --dark-shadow: 4px 5px 15px rgba(5, 6, 6, 0.4);
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: var(--bg-color);
  color: var(--dark-text-color);
  font-family: "Montserrat Alternates", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* GLOBAL STYLE */
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
  color: unset;
}

hr {
  border: none;
  border-bottom: 1px solid var(--divider-color);
}

h1,
h2,
h3,
h4 {
  letter-spacing: var(--letter-spacing);
  text-transform: capitalize;
  line-height: 1.25;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  color: inherit;
  font-size: 1rem;
  letter-spacing: var(--letter-spacing);
}
@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 2rem;
  }
  h4 {
    font-size: 1.25rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}

.btn {
  display: inline-block;
  border: none;
  padding: 0.4rem 1.25rem;
  border-radius: var(--btn-radius);
  box-shadow: var(--light-shadow);
  letter-spacing: var(--letter-spacing);
  font-size: 0.55rem;
  transition: var(--btn-transition);
  cursor: pointer;
  font-weight: 700;
  text-transform: uppercase;
}

.icon-btn {
  padding: 0.4rem 0.2rem;
  background-color: transparent;
  box-shadow: unset;
  justify-self: center;
}

.icon,
.nav-icon {
  padding: 0;
  margin: 0;
  transition: var(--btn-transition);
}

.icon {
  font-size: 1rem;
  color: var(--primary-blue);
}

/* Project CSS */
.section-center {
  width: 95%;
  margin: auto;
  padding: 1rem;
}

.nav {
  background-color: var(--primary-blue);
  box-shadow: var(--light-shadow);
  position:fixed;
  width: 100%;
  z-index:1000;
  top:0
}

.navHeader {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  width: 98%;
  margin: auto;
}

.nav-brand {
  display: flex;
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  color: white;
  padding: 10px;
}

.navCart {
  display: flex;
  gap: 0.2rem;
  border-radius:30px;
  background:#f1f1f1;
  padding:8px 16px;
  align-items: center;
  box-shadow: 0px 1px 3px -3px rgb(0, 0, 0,0.95) inset;
}

.navCartWrapper {
  display: flex;
  gap: 20px;
  align-items: center;
}

.cart-counter {
  border-radius: 50%;
  height: 16px;
  width: 16px;
  display: flex;
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  align-items: center;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  padding: 0.3rem;
  font-size: 0.6rem;
  background-color: var(--bg-yellow);
}

.nav-icon {
  color: white;
  font-size: 1.3rem;
}

.minus-icon {
  color: var(--delete-color);
}

.cart-section {
  display: grid;
  display: -moz-grid;
  display: -ms-grid;
  display: -webkit-grid;
  gap: 1rem;
  margin-top: 1rem;
  justify-content: start;
  width:100% !important
}

.cart-info,
.cart-item {
  display: grid;
  display: -moz-grid;
  display: -ms-grid;
  display: -webkit-grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 0.4fr;
  place-items: center;
  justify-content: center;
  grid-gap: 30px;
  width:100% !important
}

.productName {
  justify-self: center;
  -ms-grid-column-align: center;
  margin-left: 0.4rem;
  font-weight: bold;
}

.cart-item .productName {
  font-size: 0.65rem;
}

.imgContainer{
  border:1px solid #ddd
}

.productNameNav {
  font-size: 13px;
  font-weight:bold;
  width: 100%;
}

.qtyNav {
  font-size: 12px;
  font-weight: normal;
}

.qtyContainerNav{
  display: flex;
  justify-content: space-between;
}

.qty-selector {
  display: grid;
  display: -moz-grid;
  display: -ms-grid;
  display: -webkit-grid;
  place-items: center;
}

.total-section {
  display: flex;
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  width: 100% !important;
}

.card {
  display: grid;
  display: -moz-grid;
  display: -ms-grid;
  display: -webkit-grid;
  width: 300px;
  gap: 1rem;
  background: rgb(248, 248, 248);
  border-radius: var(--radius);
  border: 1px solid var(--grey-color);
}

.card-header,
.card-content {
  text-align: center;
}

.card-footer {
  display: grid;
  display: -moz-grid;
  display: -ms-grid;
  display: -webkit-grid;
  place-items: center;
}

.card-footer button {
  width: fit-content;
}

.underline {
  position: absolute;
  bottom: -9px;
  height: 4px;
  width: 30%;
  background-color: var(--secondary-blue);
  left: 50%;
  transform: translateX(-50%);
  border-radius: var(--radius);
}

.container {
  margin: 1rem auto;
  display: grid;
  display: -moz-grid;
  display: -ms-grid;
  display: -webkit-grid;
  gap: 1.5rem;
}

.imgContainer {
  height: 110px;
  width: 110px;
  display: flex;
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  align-items: center;
  border:1px solid #ddd;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom:12px
}

.cartItemNav{
  display:flex;
  width:100%;
  gap:12px;
  margin-bottom: 8px;
}

.imgContainerNav {
  height: 60px;
  width: 60px;
  min-width: 60px;
  display: flex;
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  align-items: center;
  border:1px solid #ddd;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  margin-bottom: 8px;
}

.img {
  margin: auto;
  width: 100%;
  height: auto;
  object-fit: cover;
}

@media screen and (min-width: 992px) {
  .nav {
    display: flex;
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    align-items: center;
    -moz-justify-content: space-around;
    -webkit-justify-content: space-around;
    -ms-justify-content: space-around;
    justify-content: space-around;
    height: 5rem;
  }

  .nav-icon {
    font-size: 1.4em;
  }

  .cart-item .productName {
    font-size: 0.85rem;
  }

  .total-section {
    display: flex;
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    -moz-justify-content: flex-end;
    -webkit-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end;
  }
}

.btn-group {
  display: flex;
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  -moz-justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  width: 100%;
}

.btn-selector {
  background-color: var(--primary-blue);
  border-bottom: 2px solid transparent;
  color: white;
  padding: 0.6rem 1.3rem;
}

.btn-selector:hover {
  color: var(--dark-text-color);
  background-color: var(--light-blue);
  transform: scale(1.02);
}

.center-item {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* SPINNER STYLE AND ANIMATION*/
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: var(--primary-blue);
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.plusButton {
  background: #f1f1f1 !important;
  border-color: #ccc !important;
  font-weight: bold !important;
  padding: 10px !important;
  height: 42px !important;
  line-height: 18px !important;
}
.qtyButton {
  background: #fff !important;
  border-color: #ccc !important;
  font-weight: bold !important;
  padding: 10px !important;
  height: 42px !important;
  line-height: 18px !important;
}

.trashIcon {
  font-size: 26px;
}

.logo {
  width: 150px;
  height: auto;
}

.logout {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

.pageWrapper{
  background: #f8f9fd;
  min-height: calc(100vh - 64px);
}

.form-input{
  background: #f8f9fd !important;
  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.1) !important; 
}