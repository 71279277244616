.page {
  width: 100%;
  min-height: calc(100vh - 340px);
  background-color: #f8f9fd;
}

.loginContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 60px;
}

.pageContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 60px;
}

.loginForm {
  font-family: sans-serif;
  display: flex;
  padding: 30px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 370px;
  margin: 0 auto;
  -webkit-box-shadow: 0px 4px 15px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 15px 2px rgba(0, 0, 0, 0.1);
}

.input {
  width: 100%;
  height: 35px;
  border-radius: 4px;
  padding: 4px;
  border: 1px solid #ccc;
  -webkit-box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}

input[type="submit"] {
  margin-top: 10px;
  cursor: pointer;
  font-size: 15px;
  background: #01d28e;
  border: 1px solid #01d28e;
  color: #fff;
  padding: 10px 20px;
}

input[type="submit"]:hover {
  background: #6cf0c2;
}

.button-container {
  display: flex;
  justify-content: center;
}

.login-form {
  background-color: white;
  padding: 2rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.list-container {
  display: flex;
}

.error {
  color: red;
  font-size: 12px;
}

.title {
  font-size: 25px;
  margin-bottom: 20px;
}

.titleSection {
  font-size: 25px;
  margin-bottom: 20px;
  text-align: center;
  margin-bottom: 70px;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 10px;
}

.checkoutContainer {
  margin: 0 auto;
  margin-top: 30px;
  width: 100%;
  max-width: 1200px;
}
.steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  position: relative;
}
.stepButton {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  background-color: #d1d1d1;
  transition: 0.4s;
}
.stepButton[aria-expanded="true"] {
  width: 50px;
  height: 50px;
  background-color: #3992c2;
  color: #fff;
}
.done {
  background-color: #3992c2;
  color: #fff;
}
.step-item {
  z-index: 10;
  text-align: center;
}
.stepTitle {
  font-size: 13px;
  text-align: center;
  width: 150px;
  margin-left: -50px;
}
.progress {
  -webkit-appearance: none;
  position: absolute;
  z-index: -1;
  width: 94%;
  height: 7px;
  margin-left: 18px;
  margin-bottom: 18px;
}
.progress::-webkit-progress-value {
  background-color: #3992c2;
  transition: 0.5s ease;
}
.progress::-webkit-progress-bar {
  background-color: #d1d1d1;
}
.label {
  width: 100%;
  font-size: 14px;
}
.spacer {
  height: 20px;
  clear: both;
}
.button {
  text-transform: lowercase !important;
  background: #3992c2 !important;
  transition: background 0.4s;
  border: none !important;
  padding: 8px;
}
.button:hover {
  background: #57a3cc;
}

.info {
  color: #777;
  border: 1px dashed #777;
  font-size: 12px;
  width: 88%;
  padding: 12px;
  margin-bottom: 28px;
}

.shippingdataRow {
  display: flex;
  font-size: 14px;
}

.shippingdataCol1 {
  font-weight: bold;
  width: 130px;
}

.dataContainer {
  background: #fff;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 6px;
  width: 100%;
  max-width: 1200px;
  margin:0 auto
}
