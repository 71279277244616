.cartIcon {
  font-size: 26px !important;
  color: #333;
  margin-top: 2px;
  margin-right: 2px;
}
.cartNum {
  color: #fff;
  background: #3992c2;
  width: 30px;
  height: 30px;
  font-weight: bold;
  margin-left: auto;
  border-radius: 50px;
  font-weight: bold;
  line-height: 28px;
  text-align: center;
}
.cartNumText{
  color:#222;
  font-size:13px;
  padding-right:10px;
}
.NavLink {
  text-decoration: none !important;
}
.NavLinkCart {
  text-decoration: none !important;
  align-items: center;
  display: flex;
  height: 78px
}
.home {
  color: #fff !important;
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
}
.NavSx {
  flex-wrap: nowrap !important;
}
.NavHome{
  margin-top:14px;
}
.NavHome:hover{
 color:#f1f1f1;
}
@keyframes zoomIn {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.3);
  }
}

@keyframes zoomOut {
  from {
    transform: scale(2);
  }
  to {
    transform: scale(1);
  }
}

.cartZoom {
  animation: zoomIn 0.2s;
}

.navCart{
  background-color: #fff;
  position: fixed;
  top:80px;
  right: -300px;
  border:1px solid #ddd;
  transition: all 0.3s ease-in-out; 
  opacity:0;
  padding:20px;
  width:300px;
  box-shadow: 2px 3px 5px rgba(5, 6, 6, 0.2);
  z-index:100
}

.navCartVisible{
  opacity:1;
  right:0px
}

.navCartTotal{
  padding:6px;
  display:flex;
  justify-content: space-between;
  width: 100%;
  border-top:1px solid #ccc;
  margin-top:20px;
  padding-top:20px
}
