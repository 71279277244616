.textContainer {
  margin: 0 auto;
  margin-bottom: 20px;
  color: #444;
  padding: 40px;
  font-size: 20px;
  text-align: center;
  max-width: 1300px;
  border: 1px solid #ccc;
  border-radius: 6px;
  background: url("../../assets/bgText.jpg");
  background-size: cover;
}

.title {
  font-size: 30px;
  font-weight: bold;
}
