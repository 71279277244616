.CartBottom {
  background: #fff;
  height: 64px;
  position: fixed;
  bottom: -100px;
  left: 0;
  width: 100% !important;
  border-top: 1px solid #ccc;
  box-shadow: -1px -3px 3px 1px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: -1px -3px 3px 1px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: -1px -3px 3px 1px rgba(0, 0, 0, 0.12);
  z-index: 10;
  display: flex;
  justify-content: end;
  transition: bottom 0.3s ease-in-out;
}

.CartBottomVisible {
  bottom: 0 !important
}

.cartIcon {
  font-size: 30px !important;
  color: #fff;
}
.cartButton {
  color: #fff;
  background: #3992c2;
  margin-top: 8px;
  margin-right: 8px;
  width: 200px;
  height: 44px;
  padding: 6px;
  border-radius: 6px;
  display: flex;
  transition: background 0.4s;
}
.cartButton:hover {
  background: #57a3cc;
}
.cartNum {
  color: #3992c2;
  background: #fff;
  width: 30px;
  height: 30px;
  font-weight: bold;
  margin-left: auto;
  border-radius: 4px;
  font-weight: bold;
  line-height: 28px;
  text-decoration: none !important;
  text-align: center;
}
.cartText {
  color: #fff;
  font-size: 15px;
  text-decoration: none !important;
  border: none;
  margin-left: 15px !important;
  line-height: 29px;
}
.NavLink {
  text-decoration: none !important;
}
.cartContainer {
  margin: 0 auto;
  margin-top: 30px;
  width: 100%;
  max-width: 1200px;
  padding:0
}
.totalContainer{
  border-radius: 6px;
  overflow: hidden;
}
.totalBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid #ddd;
  background: #3992c2 !important;
  color:#fff;
}
.totalFooter {
  background: #fff;
  width: 100%;
  text-align: center;
  padding: 10px 0px 30px 0px;
}
.checkoutButton {
  text-transform: lowercase !important;
  background: #3992c2 !important;
  transition: background 0.4s;
  border: none !important;
  padding: 10px 20px !important;
}
.checkoutButton:hover {
  background: #57a3cc !important;
}
.dataContainer {
  background: #fff;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 6px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
